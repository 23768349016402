import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout/layout"

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    textAlign: "center"
  }
}))

const NotFoundPage = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`query fourOFourQuery {
  fourOFour: file(relativePath: {eq: "404.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 95, layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <Layout>
      <Container maxWidth={"sm"}>
            <Typography variant="h1" className={classes.header}>
              Not found
            </Typography>
            <Typography variant="body1">
              You just hit a route that doesn&#39;t exist... use the navigation
              to find another one. <br />
              Let us know if you think this is an error{" "}
              <a
                href="mailto:info@simpleen.io"
                style={{ color: "#0f4c81", textDecoration: "none" }}
              >
                info@simpleen.io
              </a>
              .
            </Typography><br/>
            <GatsbyImage
              image={data.fourOFour.childImageSharp.gatsbyImageData}
              title="404 - Not found"
              style={{opacity: 0.6}} />

      </Container>
    </Layout>
  );
}

export default NotFoundPage
